import store from "../../store/index";

export default {
  open(body: string, isCatraca: boolean) {
    store.commit("modalProcuraIngresso", {
      display: true,
      body: body,
      isCatraca: isCatraca,
    });
  },
  close() {
    store.commit("modalProcuraIngresso", {
      display: false,
    });
  },
};
