import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-50 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-6 p-4 bg-white rounded-lg shadow-md"
}
const _hoisted_4 = { class: "flex flex-col bg-gray-100 p-4 rounded-lg shadow-sm" }
const _hoisted_5 = {
  class: "text-center font-bold text-gray-800",
  style: {"font-size":"22px"}
}
const _hoisted_6 = { class: "text-center text-gray-700" }
const _hoisted_7 = { class: "text-lg" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "text-lg" }
const _hoisted_11 = { class: "font-semibold" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "flex flex-col gap-3 bg-gray-50 p-4 rounded-lg shadow-sm" }
const _hoisted_14 = { class: "flex flex-col gap-2 overflow-auto h-40" }
const _hoisted_15 = { class: "font-semibold" }
const _hoisted_16 = {
  key: 1,
  class: "flex flex-col gap-6 p-4 bg-white rounded-lg shadow-md"
}
const _hoisted_17 = { class: "flex flex-col bg-gray-100 p-4 rounded-lg shadow-sm" }
const _hoisted_18 = { class: "text-center font-bold text-gray-800" }
const _hoisted_19 = { class: "flex flex-col gap-3 bg-gray-50 p-4 rounded-lg shadow-sm" }
const _hoisted_20 = { class: "flex flex-col gap-2 overflow-auto h-40" }
const _hoisted_21 = { class: "font-semibold" }
const _hoisted_22 = { class: "font-semibold" }
const _hoisted_23 = { class: "buttons mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalConfirmarConfiguracao.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          "x-transition:enter": "transition ease-out duration-150",
          "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
          "x-transition:enter-end": "opacity-100",
          "x-transition:leave": "transition ease-in duration-150",
          "x-transition:leave-start": "opacity-100",
          "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
          onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)), ["escape"])),
          class: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
          role: "dialog"
        }, [
          _createElementVNode("div", _hoisted_2, [
            (!$setup.status)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-center text-xl font-semibold text-SC_Azul" }, " Deseja prosseguir com a configuração de leitura? ", -1)),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString($setup.capitalizeFirstLetter($setup.configuracaoItems.type)), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString($setup.configuracaoItems.data_inicial), 1),
                        _cache[1] || (_cache[1] = _createTextVNode(" - ")),
                        _createElementVNode("span", _hoisted_9, _toDisplayString($setup.configuracaoItems.hora_inicial), 1)
                      ]),
                      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-sm text-gray-500" }, "Até", -1)),
                      _createElementVNode("p", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString($setup.configuracaoItems.data_final), 1),
                        _cache[2] || (_cache[2] = _createTextVNode(" - ")),
                        _createElementVNode("span", _hoisted_12, _toDisplayString($setup.configuracaoItems.hora_final), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-center text-lg font-medium text-gray-800" }, " Produtos Selecionados ", -1)),
                    _createElementVNode("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "p-2 bg-white rounded-md shadow-sm text-gray-700 text-center"
                        }, [
                          _createElementVNode("span", _hoisted_15, _toDisplayString(item.id), 1),
                          _createTextVNode(" - " + _toDisplayString(item.detalhes), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            ($setup.status)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "text-center text-xl font-semibold text-SC_Azul" }, " Deseja prosseguir com a alteraçao de status? ", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("p", _hoisted_18, _toDisplayString($setup.status), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "text-center text-lg font-medium text-gray-800" }, " Cargas Selecionadas ", -1)),
                    _createElementVNode("div", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "p-2 bg-white rounded-md shadow-sm text-gray-700 text-center"
                        }, [
                          _createElementVNode("span", _hoisted_21, _toDisplayString(item.id) + " - ", 1),
                          _createElementVNode("span", _hoisted_22, _toDisplayString(item.detalhes), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Button, {
                onClick: $setup.close,
                color: "yellow-500",
                text: "Sair"
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                onClick: $setup.confirmar,
                color: "primary",
                text: "Confirmar"
              }, null, 8, ["onClick"])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}