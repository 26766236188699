
import { useStore } from "vuex";


export default {
  name: "modalCargaCatraca",

  setup() {
    const store = useStore();


    const close = () => {
      store.commit("modalCargaCatraca", {
        display: false,
        carga: [],
        header: []
      });
    };



    return { store, close };
  }

}
