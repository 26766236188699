<template>
    <div v-if="store.getters.modalConfirmarCarga.display" x-transition:enter="transition ease-out duration-150"
        x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
        x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0" class="
      fixed
      inset-0
      z-50
      flex
      items-end
      bg-black bg-opacity-50
      sm:items-center sm:justify-center
    ">
        <div x-transition:enter="transition ease-out duration-150"
            x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
            x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
            x-transition:leave-end="opacity-0  transform translate-y-1/2" @keydown.escape="closeModal"
            class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
            role="dialog">

            <header class="flex justify-between">
                <h3>Atenção!</h3>
                <button @click="close"
                    class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                    aria-label="close">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
                        <path
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd" fill-rule="evenodd"></path>
                    </svg>
                </button>
            </header>
            <div class="modal">
                <span>Lembre-se que essa ação não podera ser revertida. Todas as leituras feitas nesse evento vão deixar de
                    existir, deseja mesmo continuar?</span>
                <div class="my-5">
                    <div class="input">

                        <Input v-model="password" type="password" :showTip="false" placeholder="*******" label="Senha" />
                        <p v-if="msg" class="msg">
                            *você digitou a senha errada.
                        </p>
                    </div>
                    <div class="my-5 flex justify-between">
                        <Button @click="close" color="yellow-500" text="Sair" />
                        <Button @click="action" color="primary" text="Confirmar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";
import { useStore } from "vuex";
export default {

    components: {
        Input,
        Button,
    }
    ,
    setup() {
        const store = useStore();
        const password = ref('')
        const msg = ref(false)
        const close = () => {
            store.commit("modalConfirmarCarga", { display: false });
        };

        const action = () => {
            if (password.value === 'MiqzjQhrEw') {
                store.getters.modalConfirmarCarga.callbackOK();
                password.value = ''
                close();
            }else{
                password.value = ''
                msg.value = true
            }
        }
        return { store, close, action, password, msg };
    },
};
</script>

<style>
.confirmar {
    width: 98%;
    display: inline-block;
    margin: 5px;
}

.buttons {
    display: flex;
    justify-content: space-around;
}

.msg {
    font-size: 12px;
    color: red;
}
</style>