/* eslint-disable */
import store from '../../store/index'

export default {
    open(items='', callback=() => {},carga){
        store.commit('modalConfiguracao',{
            display: true,
            items: items,
            carga:carga,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalConfiguracao', {
            display: false,
            items: {},
            callbackOK: () => {}
        });
    },
}