
import { useStore } from "vuex";

import { onMounted, ref, inject, onUpdated, watch } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";

import { GET, DELETE, POST } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import alert from "@/services/alert";
import { capitalizeFirstLetter } from "@/services/formater";

export default {
  name: "modalConfirmarConfiguracao",
  components: {
    Button,
  },

  setup() {
    /* eslint-disable */
    const store = useStore();
    const route = useRoute();
    const loader: any = inject("loading");
    const dataItems = ref([]) as any;
    const configuracaoItems = ref({
      evento_id: "",
      type: "",
      data_inicial: "",
      hora_inicial: "",
      data_final: "",
      hora_final: "",
      produtos_id: [] as any
    })
    const status = ref('');
    const alert = inject("alert") as any;


    const close = () => {
      store.commit("modalConfirmarConfiguracao", { display: false });
    };


    const confirmar = async () => {

      dataItems.value = []

      configuracaoItems.value = {
        evento_id: "",
        type: "",
        data_inicial: "",
        hora_inicial: "",
        data_final: "",
        hora_final: "",
        produtos_id: []
      }
      status.value = ''

      store.getters.modalConfirmarConfiguracao.callbackOK()
    };

    watch(() => store.getters.modalConfirmarConfiguracao.display, async () => {
      if (store.getters.modalConfirmarConfiguracao.display) {
        dataItems.value = store.getters.modalConfirmarConfiguracao.items.produtos
        configuracaoItems.value = store.getters.modalConfirmarConfiguracao.items.configuracao
        status.value= store.getters.modalConfirmarConfiguracao.items.status
      }
    });


    return { store, close, confirmar, dataItems, configuracaoItems, capitalizeFirstLetter,status };
  },
};
