import moment from "moment-timezone";
import { DateTime } from "luxon";

const numberToCpf = (n: string) =>
  n.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
const numberToCnpj = (n: string) =>
  n.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
const cpfToNumber = (n: string) => n.replace(/[^\w\s]/gi, "");

const toMoney = (n: string) =>
  parseFloat(n).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  });

const toDate = (d: string): string => {
  let firstSplit: string;
  let day: string;
  let month: string;
  let year: string;
  if(d) {
  if (d.length > 0) {
    if (String(d).split("").includes("T")) {
      firstSplit = d.split("T")[0];
      day = firstSplit.split("-")[2];
      month = firstSplit.split("-")[1];
      year = firstSplit.split("-")[0];
    } else {
      firstSplit = String(d).split(" ")[0];
      day = firstSplit.split("-")[2];
      month = firstSplit.split("-")[1];
      year = firstSplit.split("-")[0];
    }
    return `${day}/${month}/${year}`;
  }
  }
  return "";
};
const toDateUS = (d: string): string => {
  const firstSplit: string = d.split(" ")[0];
  const day: string = firstSplit.split("-")[2];
  const mounth: string = firstSplit.split("-")[1];
  const year: string = firstSplit.split("-")[0];
  return `${year}-${mounth}-${day}`;
};

const dataAtualFormatada = (padrao) => {
  const data = new Date();
  const dia = data.getDate().toString();
  const diaF = dia.length == 1 ? "0" + dia : dia;
  const mes = (data.getMonth() + 1).toString();
  const mesF = mes.length == 1 ? "0" + mes : mes;
  const anoF = data.getFullYear();

  if (padrao === "br") return `${diaF}/${mesF}/${anoF}`;
  else if (padrao === "us") return `${anoF}-${mesF}-${diaF}`;
};

function formatDate(data: Date) {
  const dia = data.getDate().toString();
  const diaF = dia.length == 1 ? "0" + dia : dia;
  const mes = (data.getMonth() + 1).toString();
  const mesF = mes.length == 1 ? "0" + mes : mes;
  const anoF = data.getFullYear();
  return `${diaF}-${mesF}-${anoF}`;
}

function formatDateEnvio(dataString: string) {
  const data = new Date(dataString);
  const dataUTC = new Date(data.getTime() + data.getTimezoneOffset() * 60000);

  const dia = dataUTC.getUTCDate().toString();
  const diaF = dia.length === 1 ? "0" + dia : dia;
  const mes = (dataUTC.getUTCMonth() + 1).toString();
  const mesF = mes.length === 1 ? "0" + mes : mes;
  const anoF = dataUTC.getUTCFullYear();
  return `${diaF}/${mesF}/${anoF}`;
}

const dateWithTimeBrtoStamp = (
  date: string,
  time: string,
  timeZone: string
) => {
  const correctDate = date
    .replace("/", "-")
    .replace("/", "-")
    .split("-")
    .reverse()
    .join("-");

  const aux = correctDate + " " + time;

  /*if (timeZone === 'UTC') {
        return aux
    }   
    */
  const data = moment(aux).tz(timeZone);

  return data.utc();
};

const convertToUTC = (
  dateParam: string,
  timeParam: string,
  userTimeZone: string
) => {
  const correctDate = dateParam
    .replace("/", "-")
    .replace("/", "-")
    .split("-")
    .reverse()
    .join("-");

  const userDateString = correctDate + " " + timeParam + ":00";

  if (userTimeZone === "UTC") {
    // Se a timeZone do usuário for UTC, não é necessário fazer a conversão
    return userDateString;
  } else {
    // Testamos com horaris de America/Toronto era para ser 4 hrs mas está adicionando 1 hora a mais.
    // forçaremos todos a ficar com timeZone UTC no Banco
    const userDateTime = DateTime.fromFormat(
      userDateString,
      "yyyy-MM-dd HH:mm:ss",
      { zone: userTimeZone }
    );
    const utcDateTime = userDateTime.toUTC();
    return utcDateTime.toISO();
  }
};

const dateWithTimeToBRTimeZone = (date: string, time: string) => {
  const correctDate = date
    .replace("/", "-")
    .replace("/", "-")
    .split("-")
    .reverse()
    .join("-");

  const aux = correctDate + " " + time;

  return moment(aux).tz("America/Sao_Paulo").toString();
};

const dateBrtoStamp = (date: string) => {
  return date
    .replace("/", "-")
    .replace("/", "-")
    .split("-")
    .reverse()
    .join("-");
};

function convertDateToDBDefault(dateString: string): string {
  const date = new Date(dateString + 'T00:00:00.000Z');
  
  date.setUTCHours(date.getUTCHours() + 3);
  
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

const jsonFormatter = (text: string) => {
  return JSON.stringify(JSON.parse(text), null, 2);
};

const numberToCel = (text: string) => {
  text = text.replace(/\D/g, "");
  text = text.substring(0, 11);
  text = text.replace(/(\d{2})(\d)/, "($1) $2");
  text = text.replace(/(\d{5})(\d{4})$/, "$1-$2");
  return text;
};

const numberToCep = (text: string) => {
  text = text.replace(/\D/g, "");
  text = text.substring(0, 8);
  text = text.replace(/(\d{5})(\d)/, "$1-$2");
  return text;
};

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}


export {
  numberToCpf,
  numberToCel,
  numberToCep,
  cpfToNumber,
  toDate,
  toDateUS,
  toMoney,
  numberToCnpj,
  dataAtualFormatada,
  dateBrtoStamp,
  dateWithTimeToBRTimeZone,
  dateWithTimeBrtoStamp,
  convertToUTC,
  jsonFormatter,
  formatDate,
  formatDateEnvio,
  convertDateToDBDefault,
  capitalizeFirstLetter
};
