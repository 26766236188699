/* eslint-disable */
import store from '../../store/index'

export default {
    open(items='', callback=() => {}){
        store.commit('modalConfirmarConfiguracao',{
            display: true,
            items: items,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalConfirmarConfiguracao', {
            display: false,
            items: {},
            callbackOK: () => {}
        });
    },
}