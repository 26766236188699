import store from '../../store/index'

export default {
    open(value,header){
        store.commit('modalCargaCatraca',{
            display: true,
            carga : value,
            header:header
        })
    },
    close(){
        store.commit('modalCargaCatraca', {
            display: false,
            carga:[] ,
            header:[]
        });
    },
}