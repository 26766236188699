import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '../store/index'
import alert from './services/alert'
import modal from './services/modal'
import modalconfirmation from './services/modalconfirmation'
import modalConfiguracao from './services/modalConfiguracao'
import modalConfirmarConfiguracao from './services/modalConfirmarConfiguracao'
import modaltrocamotivo from './services/modaltrocamotivo'
import modalEventoSantaFicha from './services/modalEventoSantaFicha'
import modalEmail from './services/modalEmail'
import modalQrcode from './services/modalQrcode'
import loading from './services/loading'
import './assets/styles/index.css'
import './assets/styles/icons.css'
import Maska from 'maska'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Multiselect from 'vue-multiselect'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import modalCarga from './services/modalCarga'
import modalLogs from './services/modalLogs'
import modalLogError from './services/modalLogError'
import modalConfirmarCarga from './services/modalConfirmarCarga'
import modalRelatorioCatraca from './services/relatorioCatraca'
import modalPassaporte from './services/modalPassaporte'
import modalCargaCatraca from './services/modalCargaCatraca'
import modalProcuraIngresso from './services/modalProcuraIngresso'


library.add(fas, far)

const app = createApp(App);


app
    .use(store)
    .use(router)
    .use(Maska)
    //.use(VueApexCharts)
    .component('multiselect', Multiselect)
    .component('fa', FontAwesomeIcon)
    .component('qrcode', VueQrcode)
    .provide('alert', alert)
    .provide('loading', loading)
    .provide('modal', modal)
    .provide('modalconfirmation', modalconfirmation)
    .provide('modalConfiguracao', modalConfiguracao)
    .provide('modalConfirmarConfiguracao', modalConfirmarConfiguracao)
    .provide('modaltrocamotivo', modaltrocamotivo)
    .provide('modalEventoSantaFicha', modalEventoSantaFicha)
    .provide('modalEmail', modalEmail)
    .provide('modalQrcode', modalQrcode)
    .provide('modalCarga', modalCarga)
    .provide('modalLogs', modalLogs)
    .provide('modalLogError', modalLogError)
    .provide('modalConfirmarCarga', modalConfirmarCarga)
    .provide('modalRelatorioCatraca', modalRelatorioCatraca)
    .provide('modalPassaporte', modalPassaporte)
    .provide('modalCargaCatraca', modalCargaCatraca)
    .provide('modalProcuraIngresso', modalProcuraIngresso)


    .mount('#app')
