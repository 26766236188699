import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-50 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full px-6 py-4 overflow-hidden rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  style: {"background-color":"#F1F2F6"}
}
const _hoisted_3 = { class: "flex flex-col gap-7" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center gap-4 w-full"
}
const _hoisted_5 = { class: "flex flex-row gap-3 items-center justify-between w-44" }
const _hoisted_6 = { style: {"font-size":"17px","color":"#757575"} }
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex flex-row gap-3 items-center justify-center" }
const _hoisted_11 = { style: {"font-size":"17px","color":"#757575"} }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalPassaporte.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("h6", {
              class: "text-center",
              style: {"color":"#757575","font-size":"21px"}
            }, "Leituras por dia", -1)),
            ($setup.diasV === 'Passaporte')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.daysOfWeek, (day, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-full flex flex-col items-center justify-center w-72"
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(day), 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_fa, {
                            icon: "window-minimize",
                            class: "cursor-pointer flex-shrink-0",
                            onClick: ($event: any) => ($setup.subtrairQuantidade(day)),
                            style: {"color":"#8E8E8E","font-size":"13px"}
                          }, null, 8, ["onClick"]),
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => (($setup.quantidadePorDia[day]) = $event),
                            type: "text",
                            class: "border w-12 rounded-lg py-2 appearance-none focus:outline-white form-input text-center"
                          }, null, 8, _hoisted_8), [
                            [_vModelText, $setup.quantidadePorDia[day]]
                          ]),
                          _createVNode(_component_fa, {
                            icon: "plus",
                            class: "cursor-pointer flex-shrink-0",
                            onClick: ($event: any) => ($setup.somarQuantidade(day)),
                            style: {"color":"#8E8E8E","font-size":"13px"}
                          }, null, 8, ["onClick"])
                        ])
                      ])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString($setup.diasV), 1),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_fa, {
                        icon: "window-minimize",
                        class: "mx-2 cursor-pointer flex-shrink-0",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.subtrairQuantidade($setup.diasV))),
                        style: {"color":"#8E8E8E","font-size":"13px"}
                      }),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.quantidadePorDia[$setup.diasV]) = $event)),
                        type: "text",
                        name: "quantidadeDia",
                        class: "border w-12 rounded-lg py-2 appearance-none focus:outline-white form-input mt-1 block text-sm text-center"
                      }, null, 512), [
                        [_vModelText, $setup.quantidadePorDia[$setup.diasV]]
                      ]),
                      _createVNode(_component_fa, {
                        icon: "plus",
                        class: "mx-2 cursor-pointer flex-shrink-0",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.somarQuantidade($setup.diasV))),
                        style: {"color":"#8E8E8E","font-size":"13px"}
                      })
                    ])
                  ])
                ])),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_Button, {
                onClick: $setup.confirmar,
                class: "py-2 px-5",
                style: {"background-color":"#E95D88"},
                text: "Confirmar",
                id: "confirmarPassaporte"
              }, null, 8, ["onClick"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}