
import { useStore } from "vuex";

import { onMounted, ref, inject, onUpdated, watch } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";

import { GET, DELETE, POST } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import alert from "@/services/alert";

import { dateBrtoStamp } from "@/services/formater";
import router from "@/router";

export default {
  name: "modalConfiguracao",
  components: {
    Button,
    Input
  },

  setup() {
    /* eslint-disable */
    const store = useStore();
    const route = useRoute();
    const loader: any = inject("loading");
    const dataItems = ref([]);
    const alert = inject("alert") as any;


    const close = () => {
      data.value = {
        evento_id: "",
        type: "",
        data_inicial: "",
        hora_inicial: "",
        data_final: "",
        hora_final: "",
        produtos_id: []
      }
      store.commit("modalConfiguracao", { display: false, items: [], callbackOK: () => { } });
    };

    const data = ref({
      evento_id: "",
      type: "",
      data_inicial: "",
      hora_inicial: "",
      data_final: "",
      hora_final: "",
      produtos_id: [] as any,
    });

    const status = ref("");

    const confirmar = async () => {

      if (data.value.type == "") {
        alert.open("Atenção!", "Selecione catraca/aplicativo para prosseguir", "warning");
        return;
      } else if (data.value.data_inicial == "" || data.value.hora_inicial == "" || data.value.data_final == "" || data.value.hora_final == "") {
        alert.open("Atenção!", "Preencha todos os campos para prosseguir", "warning");
        return;
      }

      data.value.evento_id = store.getters.modalConfiguracao.items.eventoId
      data.value.produtos_id = dataItems.value.map((item: any) => item.id)

      store.commit('modalConfirmarConfiguracao', {
        display: true,
        items: { produtos: dataItems.value, configuracao: data.value },
        callbackOK: () => { callbackConfirmar(data.value) }
      })

    };





    const confirmarStatus = async () => {
      console.log(status.value)
      if (!status.value) {
        alert.open("Atenção!", "Selecione um status", "warning");
        return;
      }
      store.commit('modalConfirmarConfiguracao', {
        display: true,
        items: { produtos: dataItems.value, status: status.value },
        callbackOK: () => { callbackStatusConfirmar(dataItems.value,status.value) }
      })

    };

    const callbackConfirmar = async (item: any) => {

      try {
        loader.open()

        item.data_inicial = dateBrtoStamp(item.data_inicial)
        item.data_final = dateBrtoStamp(item.data_final)

        const response = await POST('produto/config_horas_ingresso', item)

        if (response.success) {
          alert.open("Sucesso!", "Configuração de leitura realizada com sucesso", "success");
        } else {
          alert.open("Erro!", "Erro ao tentar configurar leitura", "error");
        }

      } catch (e) {
        alert.open("Erro!", "Erro ao tentar configurar leitura", "error");

      } finally {
        store.commit("modalConfirmarConfiguracao", { display: false, items: [], callbackOK: () => { } });
        store.commit("modalConfiguracao", { display: false, items: [], callbackOK: () => { } });

        loader.close()

        window.location.reload()
      }
    }

    const callbackStatusConfirmar = async (item: any,status) => {

      try {
        loader.open()

      const data = {
        status:status === 'Refazer' ? 'espera' : 'cancelado',
        lotes: item.map((item: any) => item.id)
      }

        const response = await POST('gerenciar-lotes/alter_status', data)

        if (response.success) {
          alert.open("Sucesso!", "Status alterado com sucesso", "success");
        } else {
          alert.open("Erro!", "Erro ao tentar alterar status", "error");
        }

      } catch (e) {
        alert.open("Erro!", "Erro ao tentar alterar status", "error");

      } finally {
        store.commit("modalConfirmarConfiguracao", { display: false, items: [], callbackOK: () => { } });
        store.commit("modalConfiguracao", { display: false, items: [], callbackOK: () => { } });

        loader.close()
      }
    }

    watch(() => store.getters.modalConfiguracao.display, async () => {
      if (store.getters.modalConfiguracao.display) {
        dataItems.value = store.getters.modalConfiguracao.items.items
      }
    });


    return { store, close, confirmar, dataItems, data, confirmarStatus, status };
  },
};
