import { createStore } from "vuex";
/* eslint-disable */

const store = createStore({
  state: {
    name: "Vue",
    alert: {
      display: false,
      title: "",
      message: "",
      type: "",
    },
    modal: {
      display: false,
      title: "",
      message: "",
      input: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: "",
    },
    modalPassaporte: {
      display: false,
      dias: "",
      config: "",
      callbackOK: () => {}
    },
    modalConfiguracao: {
      display: false,
      items: "",
      callbackOK: () => {}
    },
    modalConfirmarConfiguracao: {
      display: false,
      items: "",
      callbackOK: () => {}
    },
    modalIngresso: {
      display: false,
      title: "",
      message: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: "",
    },
    modalConfirmation: {
      display: false,
      id: "",
      prePedido: "",
      callbackOK: "",
      callbackCancel: "",
    },
    modalTrocaMotivo: {
      display: false,
      id: "",
      callbackOK: "",
      callbackCancel: "",
    },
    loading: false,

    modalEventoSantaFicha: {
      display: false,
      callbackOK: "",
      callbackCancel: "",
    },

    modalRelatorioCatraca: {
      display: false,
      'id_evento':''
    },

    userid: "",

    modalEmail: {
      display: false,
      tipoIngresso: "",
    },

    modalCarga: {
      display: false,
      carga: [],
    },

    modalQrcode: {
      display: false,
      qrcodes: [],
    },
    modalLogs: {
      display: false,
      body: "",
      method: "",
    },
    modalLogError:{
      display: false,
      body: "",
      method: "",
      message: ""
    },

    modalConfirmarCarga: {
      display: false,
      callbackOK: "",
      callbackCancel: "",
    },
    modalCargaCatraca: {
      display: false,
      carga: [],
      header: [],

    },

    modalProcuraIngresso: {
      display: false,
      body: "",
      method: "",
    }
  },

  getters: {
    alert: (state) => state.alert,
    loading: (state) => state.loading,
    modal: (state) => state.modal,
    modalConfirmation: (state) => state.modalConfirmation,
    modalConfiguracao: (state) => state.modalConfiguracao,
    modalConfirmarConfiguracao: (state) => state.modalConfirmarConfiguracao,
    modalTrocaMotivo: (state) => state.modalTrocaMotivo,
    modalIngresso: (state) => state.modalIngresso,
    modalEventoSantaFicha: (state) => state.modalEventoSantaFicha,
    user: (state) => state.userid,
    modalEmail: (state) => state.modalEmail,
    modalQRCode: (state) => state.modalQrcode,
    modalCarga: (state) => state.modalCarga,
    modalCargaCatraca: (state) => state.modalCargaCatraca,
    modalLogs: (state) => state.modalLogs,
    modalLogError: (state) => state.modalLogError,
    modalConfirmarCarga: (state) => state.modalConfirmarCarga,
    modalRelatorioCatraca: (state) => state.modalRelatorioCatraca,
    modalPassaporte: (state) => state.modalPassaporte,
    modalProcuraIngresso: (state) => state.modalProcuraIngresso,
  },

  mutations: {
    changeName: (state, value) => (state.name = value),
    loading: (state, value) => (state.loading = value),
    alert: (state, value) => (state.alert = value),
    modal: (state, value) => (state.modal = value),
    modalIngresso: (state, value) => (state.modalIngresso = value),
    modalConfirmation: (state, value) => (state.modalConfirmation = value),
    modalConfiguracao: (state, value) => (state.modalConfiguracao = value),
    modalConfirmarConfiguracao: (state, value) => (state.modalConfirmarConfiguracao = value),
    modalTrocaMotivo: (state, value) => (state.modalTrocaMotivo = value),
    modalEventoSantaFicha: (state, value) =>
      (state.modalEventoSantaFicha = value),
    user: (state, value) => (state.userid = value),
    modalEmail: (state, value) => (state.modalEmail = value),
    modalQRCode: (state, value) => (state.modalQrcode = value),
    modalCarga: (state, value) => (state.modalCarga = value),
    modalCargaCatraca: (state, value) => (state.modalCargaCatraca = value),
    modalLogs: (state, value) => (state.modalLogs = value),
    modalLogError: (state, value) => (state.modalLogError = value),
    modalConfirmarCarga: (state, value) => (state.modalConfirmarCarga = value),
    modalRelatorioCatraca: (state, value) => (state.modalRelatorioCatraca = value),
    modalPassaporte: (state, value) => (state.modalPassaporte = value),
    modalProcuraIngresso: (state, value) => (state.modalProcuraIngresso = value), 

  },
});

export default store;
