import store from '../../store/index'

export default {
    open(id: string, prePedido: string){
        store.commit('modalConfirmation',{
            display: true,
            id: id,
            prePedido: prePedido,
        })
    },
    close(){
        store.commit('modalConfirmation', {
            display: false,
        });
    },
}