import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-50 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_4 = { class: "flex gap-1 items-center justify-between" }
const _hoisted_5 = {
  class: "text-center",
  style: {"font-size":"18px"}
}
const _hoisted_6 = { style: {"font-weight":"bolder"} }
const _hoisted_7 = { class: "flex flex-row gap-4 justify-center items-center" }
const _hoisted_8 = { class: "flex flex-row gap-3 items-center" }
const _hoisted_9 = { class: "flex flex-row gap-3 items-center" }
const _hoisted_10 = { class: "flex flex-col gap-2 justify-center mt-3" }
const _hoisted_11 = { class: "flex lg:flex-row flex-col" }
const _hoisted_12 = { class: "w-full p-2" }
const _hoisted_13 = { class: "w-full p-2" }
const _hoisted_14 = { class: "flex lg:flex-row flex-col" }
const _hoisted_15 = { class: "w-full p-2" }
const _hoisted_16 = { class: "w-full p-2" }
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col gap-1"
}
const _hoisted_18 = { class: "flex gap-1 items-center justify-between" }
const _hoisted_19 = {
  class: "text-center",
  style: {"font-size":"18px"}
}
const _hoisted_20 = { style: {"font-weight":"bolder"} }
const _hoisted_21 = { class: "flex flex-row gap-4 justify-center items-center" }
const _hoisted_22 = { class: "w-full p-2" }
const _hoisted_23 = { class: "buttons mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalConfiguracao.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          "x-transition:enter": "transition ease-out duration-150",
          "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
          "x-transition:enter-end": "opacity-100",
          "x-transition:leave": "transition ease-in duration-150",
          "x-transition:leave-start": "opacity-100",
          "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
          onKeydown: _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)), ["escape"])),
          class: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
          role: "dialog"
        }, [
          _createElementVNode("div", _hoisted_2, [
            (!$setup.store.getters.modalConfiguracao.carga)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "text-center mt-4 subtitle2 text-SC_Azul" }, " Configuração de leitura", -1)),
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args))),
                      class: "inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700",
                      "aria-label": "close"
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("svg", {
                        class: "w-10 h-10",
                        fill: "currentColor",
                        viewBox: "0 0 20 20",
                        role: "img",
                        "aria-hidden": "true"
                      }, [
                        _createElementVNode("path", {
                          d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                          "clip-rule": "evenodd",
                          "fill-rule": "evenodd"
                        })
                      ], -1)
                    ]))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_5, [
                      _cache[13] || (_cache[13] = _createTextVNode(" Produtos selecionados: ")),
                      _createElementVNode("span", _hoisted_6, _toDisplayString($setup.dataItems.length), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        id: "catraca",
                        name: "options",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.data.type) = $event)),
                        value: "catraca"
                      }, null, 512), [
                        [_vModelRadio, $setup.data.type]
                      ]),
                      _cache[14] || (_cache[14] = _createElementVNode("label", { for: "catraca" }, "Catraca", -1))
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        id: "aplicativo",
                        name: "options",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.data.type) = $event)),
                        value: "aplicativo"
                      }, null, 512), [
                        [_vModelRadio, $setup.data.type]
                      ]),
                      _cache[15] || (_cache[15] = _createElementVNode("label", { for: "aplicativo" }, "Aplicativo", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_Input, {
                          type: "customDate",
                          placeholder: 'Dia/Mês/Ano',
                          id: "dataInicio",
                          label: "Data inicial",
                          modelValue: $setup.data.data_inicial,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.data.data_inicial) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_Input, {
                          id: "horaInicio",
                          type: "customTime",
                          label: "Hora de Inicio",
                          placeholder: "Hora inicial",
                          name: 'horaInicio',
                          modelValue: $setup.data.hora_inicial,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.data.hora_inicial) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_Input, {
                          type: "customDate",
                          placeholder: 'Dia/Mês/Ano',
                          id: "dataFim",
                          label: "Data final",
                          modelValue: $setup.data.data_final,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.data_final) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_Input, {
                          id: "horaInicio",
                          type: "customTime",
                          label: "Hora final",
                          placeholder: "Hora final",
                          name: 'horaInicio',
                          modelValue: $setup.data.hora_final,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.hora_final) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            ($setup.store.getters.modalConfiguracao.carga)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "text-center mt-4 subtitle2 text-SC_Azul" }, " Alterar Status das cargas", -1)),
                    _createElementVNode("button", {
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args))),
                      class: "inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700",
                      "aria-label": "close"
                    }, _cache[16] || (_cache[16] = [
                      _createElementVNode("svg", {
                        class: "w-10 h-10",
                        fill: "currentColor",
                        viewBox: "0 0 20 20",
                        role: "img",
                        "aria-hidden": "true"
                      }, [
                        _createElementVNode("path", {
                          d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                          "clip-rule": "evenodd",
                          "fill-rule": "evenodd"
                        })
                      ], -1)
                    ]))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_19, [
                      _cache[18] || (_cache[18] = _createTextVNode(" Cargas selecionadas: ")),
                      _createElementVNode("span", _hoisted_20, _toDisplayString($setup.dataItems.length), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_Input, {
                        type: "select",
                        placeholder: "Status",
                        label: "Status",
                        modelValue: $setup.status,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.status) = $event)),
                        data: [
                { label: 'Selecione um Status', value: '' },
                { label: 'Refazer', value: 'Refazer' },
                { label: 'Cancelar', value: 'Cancelar' },
              ]
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "" }, null, -1)),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Button, {
                onClick: $setup.close,
                color: "yellow-500",
                text: "Sair"
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                onClick: _cache[9] || (_cache[9] = () => {
            !$setup.store.getters.modalConfiguracao.carga ? $setup.confirmar() : $setup.confirmarStatus()
          }),
                color: "primary",
                text: "Confirmar"
              })
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}