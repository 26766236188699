import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-50 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "confirmar" }
const _hoisted_5 = { class: "input" }
const _hoisted_6 = {
  key: 0,
  class: "msg"
}
const _hoisted_7 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalConfirmation.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          "x-transition:enter": "transition ease-out duration-150",
          "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
          "x-transition:enter-end": "opacity-100",
          "x-transition:leave": "transition ease-in duration-150",
          "x-transition:leave-start": "opacity-100",
          "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
          onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)), ["escape"])),
          class: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
          role: "dialog"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[5] || (_cache[5] = _createElementVNode("h6", null, "Atenção!", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, "Para que se possa confirmar o cancelamento dessa venda", -1)),
            ($setup.outrasVendas.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", null, "Esse cancelamento vai afetar as seguintes vendas:", -1)),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.outrasVendas, (venda) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: venda.id,
                        class: "text-red-500"
                      }, _toDisplayString(venda.transacao), 1))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, [
              _cache[4] || (_cache[4] = _createTextVNode(" Selecione um motivo abaixo e Digite o seguinte valor no campo abaixo: ")),
              _createElementVNode("b", null, _toDisplayString($setup.store.getters.modalConfirmation.id), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createVNode(_component_Input, {
                  type: "datalist",
                  id: "motivo",
                  modelValue: $setup.motivo,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.motivo) = $event)),
                  label: "Motivo",
                  placeholder: "Selecione um motivo de cancelamento",
                  data: $setup.motivos
                }, null, 8, ["modelValue", "data"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Input, {
                  type: "default",
                  modelValue: $setup.idVenda,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.idVenda) = $event)),
                  label: "Id da venda",
                  placeholder: "Id da venda"
                }, null, 8, ["modelValue"]),
                ($setup.msg)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, " *você digitou o id errado ou não selecionou um motivo, por favor tente novamente "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Button, {
                  onClick: $setup.close,
                  color: "yellow-500",
                  text: "Sair"
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  onClick: $setup.confirmar,
                  color: "primary",
                  text: "Confirmar"
                }, null, 8, ["onClick"])
              ])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}